import axios from "axios";
import * as ENDPOINTS from "../URlcollection";

// register user phonenumber  && send otp to numbr
export const registerUser = async (mobileNumber) => {
  try {
    const url = "https://inobackend-production.up.railway.app/api/v1/register";
    const response = await axios.post(
      url,
      { mobileNumber },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);
    if (response.status === 200) {
      const responseData = response.data;

      console.log(
        "%cSuccess: status code 200",
        "color: green; font-weight: bold;"
      );
      console.log("Response Message:", responseData.responseMessage);
      return responseData.responseMessage;
    } else {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error("Registration failed. Please try again.");
      }
    }
  } catch (error) {
    console.error("An error occurred during registration:", error.message);
    throw new Error("An error occurred during registration.");
  }
};
// veification of otp  &&
export async function verifyOTP(mobileNumber, otp) {
  console.log("in")
  try {
    const response = await axios.post('https://inobackend-production.up.railway.app/api/v1/user/verifyOTP', {
      mobileNumber: `+${mobileNumber}`,
      otp: otp,
        });

  console.log(response);
    // const response = await axios.post(
    //   "https://inobackend-production.up.railway.app/api/v1/user/verifyOTP",
    //   {
    //     mobileNumber: `+${mobileNumber}`,
    //     otp: otp,
    //   },{}
    // );

    const token = response.data.token;
    localStorage.setItem("token", token);

    return response.data;
  } catch (error) {
    // Log the actual error message
    console.error("Error while verifying OTP:", error.message);
    throw error; // Rethrow the error for the caller to handle
  }
}

// register user information form *form rendering after verification of otp
export const registerUserdata = async (userData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Register_Profile,
      userData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const registeredUser = response.data;
      console.log("Registered User:", registeredUser);
      return registeredUser;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Error registering user:", error.message);
    throw new Error("Failed to register user");
  }
};
//creatig post api
export const createPost = async (postData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Post,
      postData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const postdata = response.data;
      console.log(" resposnse post:", postdata);
      return postdata;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to post ");
  }
};
// async func for post project
export const postProject = async (ProjectData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Post_Project,
      ProjectData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const projectdata = response.data;
      console.log(" resposnse post:", projectdata);
      return projectdata;
    } else {
      throw new Error("Failed");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to projectdata ");
  }
};
// async fun for  populating products cateogries in dopdown for all modules
export const categoryData = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER_API.Cateogry_Data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(`Failed to fetch category. Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching category:", error);
    throw new Error("Failed to fetch category");
  }
};
//
export const getAllProductCategories = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllProdctCategories`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch product categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//  async fun for add product // module row_#_4
export const addProduct = async (produtdata) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Product,
      produtdata,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
//  async func for find product input // module row_#_1
export const findProduct = async (produtdata) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Product,
      produtdata,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
//  async func or listing  service  // module_row_#_5
export const listService = async (service_List_Data) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Service,
      service_List_Data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};
//  async dunc for explore service // module_row_#_2
export const ExploreService = async (service_List_Data) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Service,
      service_List_Data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};
//
export const CreateJOb = async (Create_JOB_List_Data) => {
  try {
    const response = await axios.post(
      ENDPOINTS.USER_API.Create_Job,
      Create_JOB_List_Data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse List Service :", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  List Service");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to LisService ");
  }
};
//
export const ProductListDATA = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER_API.All_Product_list, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching category:", error);
    throw new Error("Failed to fetch category");
  }
};
export const getServiceListDa = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllServiceCategories`,
      {
        headers: {
          token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch service  categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//
export const ApprovedProducts = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getAllProductList`,
      {
        headers: {
          token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch products");
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
// fetch all stores list 
export const getAllStores = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/store/listUserStores`,
      {
        headers: {
          Token: accessToken,
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch store categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// register store 
export const registerSrore = async (StoreData) => {
  try {
    const response = await axios.post(
      ENDPOINTS.Store_Urls.Register_Store,
      StoreData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const registeredUser = response.data;
      console.log("Registered store:", registeredUser);
      return registeredUser;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Error registering user:", error.message);
    throw new Error("Failed to register store");
  }
};
// stre view details 
export const fetchStoreDetails = async (storeId) => {
  try {
    const apiUrl = `https://inobackend-production.up.railway.app/api/v1/store/storeView?storeId=${storeId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Failed to fetch store details. Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching store details:", error);
    throw new Error("Failed to fetch store details");
  }
};

