import React, { useState } from "react";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { registerSrore } from "../../../../api/userRegestration/CollectionForm";
export default function StoreRegestrationForm() {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    location: "",
    PhoneNumber: "",
    timing:"",
    EmailID: "",
    yearsofEstablishment: "",
  awards: [],
    Certificates: [],
  });
  //
  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    // Check if the field should be treated as an array
    if (name === "awards" || name === "Certificates") {
      // Split the value into an array, e.g., assuming values are comma-separated
      const arrayValue = value.split(',').map(item => item.trim());
      
      setFormData((prevData) => ({
        ...prevData,
        [name]: arrayValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const hanldeSubmit = async (e) => {
    console.log(formData)
    console.log("handlinfj dknf");
    e.preventDefault();
    try {
      const StoreData = {
        name: formData.name,
        mail: formData.EmailID,
        type: formData.type,
        banner:
          "https://images.unsplash.com/photo-1533518463841-d62e1fc91373?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        location: formData.location,
        timing: formData.timing,
        established: formData.yearsofEstablishment,
        awards: formData.awards,
        certificates: formData.Certificates,
        //   "catalogue":[{"name":"Shoe","description":"new","price":"2333"},{"name":"Shoe","description":"new","price":"2333"}],
        phoneNumber: formData.PhoneNumber,

      };


      const Storeinfo = await registerSrore(StoreData);
      console.log("registerd_store",Storeinfo)
    } catch (error) {
      console.error( "src:component", "Error registering store:", error.message);
    }
  };

  return (
    <>
      <div className="  bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left">
        <div className="store_form_regestration">
          <form onSubmit={hanldeSubmit}>
            <div className="flex flex-row lg:gap-[9rem] mt-[3rem] mb-[2rem]">
              {/* 1 */}
              <div className="form-input flex flex-col items-center w-[47%]">
                <h1 className="font-all font-semibold text-center">
                  RESGISTER YOUR STORE
                </h1>
                {/* 1 name field  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm px-2 text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2  bg- w-fit-content text-center"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="name"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/* 2 store type*/}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
                  <label
                    htmlFor="input"
                    className="text text-sm text-black w-max rounded-md bg-white relative top-2.5 ml-2 px-2 w-fit-content"
                  >
                    Type:
                  </label>
                  <select
                    name="type"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] lg:w-[541px] rounded-md focus:border-[1px]"
                    value={formData.type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select an option</option>
                    <option value="option1">STORE</option>
                    <option value="option2">service</option>
                    <option value="option3">company</option>
                  </select>
                </div>
                {/* 2  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="location"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/*  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max  rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                  >
                    Timing :
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="timing"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.timing}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/*  */}
                {/* 5  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Phone Number
                  </label>
                  <input
                    type="number"
                    placeholder="Write here..."
                    name="PhoneNumber"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.PhoneNumber}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/*  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                  >
                    Email ID
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="EmailID"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.EmailID}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                  >
                    Years of Establishment
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="yearsofEstablishment"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.yearsofEstablishment}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                  >
                    Awards
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="awards"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.awards}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                  >
                    Certificates
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="Certificates"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.Certificates}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/*  */}
              </div>

              {/* 2 */}

              <div className="inline-flex flex-col mt-[4rem] lg:gap-[3rem]">
                {/*  */}

                <div className=" rounded-md h-[11rem] w-[22rem] border-[1.5px] border-dashed border-black ">
                  <div className="button">
                    <AiOutlinePlusCircle className="text-[33px] relative top-[16px] lg:mt-[3rem] mx-auto " />
                    <h5 className="text-center font-all mt-4">
                      Upload Banner Photo
                    </h5>
                  </div>
                </div>
                <p className="text-[#738482]">Accepted Types : jpeg,jpg,png</p>

                {/*  */}

                {/* buttin action */}

                <div class="button-action flex flex-col items-center mt-[3.5rem]">
                  <button onClick={hanldeSubmit} class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
                    Submit Regstration{" "}
                  </button>

                  <Link to="/">
                    <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                      Go Back
                    </button>
                  </Link>
                </div>
              </div>
              {/*  */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
