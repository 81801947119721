import { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import {
  getAllProductCategories,
  listService,
} from "../../../../../api/userRegestration/CollectionForm";

export default function ListinputMain() {
  const [categories, setCategories] = useState([]);
  const accessToken = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    ServiceCateogries: "",
    ServiceName: "",
    ServiceRate: "",
    ServiceDetails: "",
    img: "",
    Relevantskills: "",
    // shareBusinessCard: false,
    // followGuidelines: false,
  });
  //

  const handlesubmit = async (event) => {
    event.preventDefault();
    try {
      const service_List_Data = {
        serviceName: "plumber",
        mediaUrl:
          "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.benjaminfranklinplumbing.com%2Frosenberg%2Fabout-us%2Fblog%2F2020%2Fmarch%2F10-reasons-why-a-professional-plumber-is-better-%2F&psig=AOvVaw3gDQlTi87MAioihIc2AAha&ust=1690714369599000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCODyoP3fs4ADFQAAAAAdAAAAABAE",
        details: "good plumber",
        rate: "5000",
        mediaType: "MEDIA",
        categorie: "plumber",
      };
      const service_Data = await listService(service_List_Data);
      console.log("service_list", service_Data);
    } catch (error) {

      console.error("Error in :", error.message);

    }
  };
  // 
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };
  //
  useEffect(() => {
    getAllProductCategories(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  return (
    <>
      <div className="form">
        <form onSubmit={handlesubmit}>
          <div className="flex flex-row lg:gap-[9rem] mt-[3rem] mb-[2rem]">
            {/* 1 */}
            <div className="form-input flex flex-col  w-[47%]">
              <h1 className="font-all font-semibold text-center">
                Sell Services
              </h1>
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Service Cateogries
                </label>
                <select
                  name="ServiceCateogries"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.ServiceCateogries}
                  onChange={handleInputChange}
                >
                  <option value="" className="p-2" disabled>
                    Select Category
                  </option>
                  {categories.map((category) => (
                    <option
                      key={category._id}
                      value={category.name}
                      className="bg-white  transition-colors duration-300 p-2"
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>{" "}
              {/* 2  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Service Name
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="ServiceName"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  // aria-autocomplete="list"
                  value={formData.ServiceName}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Service Rate
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="ServiceRate"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  // aria-autocomplete="list"
                  value={formData.ServiceRate}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              {/* 5  */}
              {/* text area  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                <label
                  htmlFor="input"
                  className="text text-sm text-black w-[7rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Service Details
                </label>
                <textarea
                  placeholder="Write here..."
                  name="ServiceDetails"
                  rows="4"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px] "
                  value={formData.ServiceDetails}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                <label
                  htmlFor="input"
                  className="text text-sm text-black w-[10rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Relevant skills/Keywords
                </label>
                <textarea
                  placeholder="Write here..."
                  name="Relevantskills"
                  rows="7"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.Relevantskills}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              {/*  */}
            </div>

            {/* 2 */}

            <div className="inline-flex flex-col mt-[4rem] lg:gap-[3rem]">
              {/*  */}
              <div className=" rounded-md h-[81px] w-[22rem] border-[1.5px] border-dashed border-black ">
                <div className="button">
                  <AiOutlinePlusCircle className="text-[33px] relative top-[16px] mx-auto " />
                  <h5 className="text-center font-all mt-4">
                    Add Product photos
                  </h5>
                </div>
              </div>

              <div className=" rounded-md h-[81px] w-[22rem] border-[1.5px] border-dashed border-black ">
                <div className="button">
                  <AiOutlinePlusCircle className="text-[33px] relative top-[16px] mx-auto " />
                  <h5 className="text-center font-all mt-4">
                    Add Product photos
                  </h5>
                </div>
              </div>

              {/*  */}
              <div className="/">
                <div class="flex items-center ">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="link-checkbox"
                    class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
                  >
                    I agree to share my Bussiness card with Supplier .
                  </label>
                </div>

                <div class="flex items-center mt-3">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="link-checkbox"
                    class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
                  >
                    I agree to follow buyer posting guideline .
                  </label>
                </div>
              </div>

              {/* buttin action */}

              <div class="button-action flex flex-col items-center mt-6">
                <button class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
                  Share Inputs{" "}
                </button>
                <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                  Create a Campaign
                </button>
              </div>
            </div>
            {/*  */}
          </div>
        </form>
      </div>
    </>
  );
}
