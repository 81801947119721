import React from 'react'
import Routes from './Routes';
import Response from './test/Response';


function App() {
  return (
    <>
      <Routes/>
  {/* <Response/> */}
    </>
  )
}

export default App