import React from 'react'
import ExploreCreatePost from './ExploreCreatePost'
import ExploreCommunityPost from './ExploreCommunityPost'
import ExploreProductSlider from './ExploreProductSlider'
import ExploreService from './ExploreService'
import ExploreJob from './ExploreJob'
import AddCreatePost from '../../AddProject/AddProjectPost/AddCreatePost'

function ExplorePostMain() {
  return (
    <>
    <AddCreatePost/>
      <ExploreCommunityPost/>
      <ExploreProductSlider/>
      <ExploreService/>
      <ExploreJob/>
    </>
  )
}

export default ExplorePostMain
