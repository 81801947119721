export const BASE_URL =
  "https://inobackend-production.up.railway.app/api/v1";

export const Store_base_url = "https://inobackend-production.up.railway.app/api/v1/store"  
export const USER_API = {
  Register_User: `${BASE_URL}/user/register`,
  Verify_OTP: `${BASE_URL}/user/verifyOTP`,
  Register_Profile: `${BASE_URL}/user/updateProfile`,
  Create_Post: `${BASE_URL}/user/createPost`,
  Post_Project: `${BASE_URL}/user/createProject`,
  Create_Product: `${BASE_URL}/user/createProduct`,
  Create_Service: `${BASE_URL}/user/createService`,
  Create_Job:`${BASE_URL}/user/createJob`,
  All_Product_list :`${BASE_URL}/user/allProductList`,
  Get_ALL_Products : `${BASE_URL}/user/getAllProductList`,
  Register_Store:`${BASE_URL}/user/registerStore`
};

export const Store_Urls = {

Register_Store : `${Store_base_url}/user/registerStore`


}
