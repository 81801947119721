import React from 'react'
import PostCreatePost from './PostCreatePost'
import PostCommunityPost from './PostCommunityPost'
import PostProductSLider from './PostProductSLider'
import PostServicePost from './PostServicePost'
import PostJobPost from './PostJobPost'
import AddCreatePost from '../../AddProject/AddProjectPost/AddCreatePost'

export default function JobPostMain() {
  return (
    <>
    <AddCreatePost/>
   {/* <PostCreatePost/> */}
   <PostCommunityPost/>
   <PostProductSLider/>
   <PostServicePost/>
   <PostJobPost/>
    </>
  )
}
