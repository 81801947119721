import React, { useEffect, useState } from "react";
import {
  getAllProductCategories,
  addProduct,
} from "../../../../../api/userRegestration/CollectionForm";

import { AiOutlinePlusCircle } from "react-icons/ai";
export default function AddProjectInput() {
  const [categories, setCategories] = useState([]);
  const accessToken = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    category: "",
    Productrate: "",
    moq: "",
    mqunit: "",
    ProductDetail: "",
    shareBusinessCard: false,
    followGuidelines: false,
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const produtdata = {
        productName: "Car",
        mediaUrl:
          "https://carwow-uk-wp-3.imgix.net/18015-MC20BluInfinito-scaled-e1666008987698.jpg?auto=format&cs=tinysrgb&fit=clip&ixlib=rb-1.1.0&q=10&w=800",
        details: "honda civic",
        amount: "3",
        mediaType: "MEDIA",
        categorie: "iphone",
      };
      const Product = await addProduct(produtdata);
      console.log("post:", Product);
    } catch (error) {
      console.error("Error in :", error.message);
    }
  };

  useEffect(() => {
    getAllProductCategories(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);

  return (
    <>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="flex sm:flex-row  md:flex-row  flex-col lg:gap-[9rem] mt-[3rem] mb-[2rem]">
            {/* 1 */}
            <div className="form-input flex flex-col  w-[47%]">
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                <label
                  htmlFor="select"
                  className="text text-sm text-black w-[8rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg-w-fit-content text-center"
                >
                  Product Categories
                </label>
                <select
                  name="category"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.category}
                  onChange={handleInputChange}
                >
                  <option value="" className="p-2" disabled>
                    Select Category
                  </option>
                  {categories.map((category) => (
                    <option
                      key={category._id}
                      value={category.name}
                      className="bg-white  transition-colors duration-300 p-2"
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* 2  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Product Rate
                </label>
                <input
                  type="number"
                  placeholder="Write here..."
                  name="Productrate"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.Productrate}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[10rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Minimum Order Quanity
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="moq"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  aria-autocomplete="list"
                  value={formData.moq}
                  onChange={handleInputChange}
                />
              </div>{" "}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  MQ UNIT
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="mqunit"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.mqunit}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              {/* 5  */}
              {/* text area  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                <label
                  htmlFor="input"
                  className="text text-sm text-black w-[7rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Product Details
                </label>
                <textarea
                  placeholder="Write here..."
                  name="ProductDetail"
                  rows="7"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.ProductDetail}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>

            {/* 2 */}

            <div className="inline-flex flex-col mt-[4rem] lg:gap-[3rem]">
              <div className=" rounded-md h-1/3 border-[1.5px] border-dashed border-black ">
                <div className="flex justify-center bg-white w-[60%]  mx-auto mt-[-12px] rounded-full">
                  Attach Supporting Document
                </div>

                <div className="button">
                  <AiOutlinePlusCircle className="text-[33px] mx-auto mt-[2rem]" />
                </div>
              </div>

              <div className="/">
                <div class="flex items-center ">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="link-checkbox"
                    class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
                  >
                    I agree to share my Bussiness card with Supplier .
                  </label>
                </div>

                <div class="flex items-center mt-3">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="link-checkbox"
                    class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
                  >
                    I agree to follow buyer posting guideline .
                  </label>
                </div>
              </div>

              {/* buttin action */}

              <div class="button-action flex flex-col items-center mt-6">
                <button
                  type="submit"
                  class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                >
                  Share Inputs{" "}
                </button>
                <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                  Create a Campaign
                </button>
              </div>
            </div>
            {/*  */}
          </div>
        </form>
      </div>
    </>
  );
}
