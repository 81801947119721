import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const navigate = useNavigate;
const handleclick = ()=>{
navigate("/creatpost")
}

export default function AddCreatePost() {

  return (
    <>
   <div className='flex flex-end  items-center' >
     <Link  to={"/addProject/creatpost"} >
    <button   className=" p-[13px] text-center font-all font-semibold transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] text-white rounded-md cursor-pointer relative justify-center my-2">Creat Post</button>
    </Link>
    </div>


    </>
  )
}
