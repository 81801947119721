import React, { useState } from "react";
import { registerUserdata } from "../../../src/api/userRegestration/CollectionForm";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";

import { useNavigate } from "react-router-dom";

export default function RegisterUserForm() {
  //
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullname: "",
    userName: "",
    email: "",
    aboutYourself: "",
    location: "",
    useCurrentLocation: "",
    interest: "",
    uuid: "",
    imageFile: null,
  });

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "text" ? value : prevData[name],
    }));
  };

  const handleSubmit = async (e) => {
    console.log(
      "sdnskdnkjdkjbidaskfbadkjbdaifkjlbdaiflukjadsbflkajsdbfialsdkabdaskjfdbfkjdsfbdkfhdsbkdsaafa"
    );
    e.preventDefault();

    try {
      const userData = {
        name: formData.fullname,
        userName: formData.userName,
        email: formData.email,
        // aboutYourself :formData.aboutYourself,
        // location:formData.location,
        // currentlocation:formData.useCurrentLocation,
        // interest:formData.interest,
        // uuid : formData.interest,
        // image:"https://w7.pngwing.com/pngs/153/31/png-transparent-netflix-macos-bigsur-icon-thumbnail.png"
      };
      const registeredUser = await registerUserdata(userData);
      console.log("Registered User:", registeredUser);
      navigate("/");
    } catch (error) {
      console.error("Error registering user:", error.message);
      // if (error.response && error.response.status === 200) {
      //   console.log("API Message:", error.response.data.message);
      // }
    }

    console.log(formData);
  };

  //
  return (
    <>
      <>
        {/* 01  */}

        <div className="flex flex-row  bg-[#cb22281c]">
          <div className=" xs:hidden  item-1 h-screen lg:w-[100vh]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>

          {/* 01 end  */}
          {/* 02  */}
          <div className="item-form">
            <section className="max-w-4xl p-6  lg:w-[93vh] sm:w-[93vh] mx-auto bg-white rounded-md shadow-md  mt-[3rem] fade-in-right">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <div>
                    <label className="text-black" htmlFor="username">
                      Full name
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="fullname"
                      value={formData.fullname}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="text-black" htmlFor="username">
                      username
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="userName"
                      value={formData.userName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="text-black" htmlFor="username">
                      email
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="text-black" htmlFor="username">
                      About yourself
                    </label>
                    {/*  */}
                    <input
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="aboutYourself"
                      value={formData.aboutYourself}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="text-black" htmlFor="username">
                      Location
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="text-black" htmlFor="username">
                      Use Current Location
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="useCurrentLocation"
                      value={formData.useCurrentLocation}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="text-black" htmlFor="username">
                      Interest
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="interest"
                      value={formData.interest}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label
                      className="text-black"
                      htmlFor="passwordConfirmation"
                    >
                      UUID
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md   focus:border-blue-500 focus:outline-none focus:ring"
                      name="uuid"
                      value={formData.uuid}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-black">
                      Image
                    </label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-black"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <p className="text-xs text-black">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="px-6 py-2 leading-5 text-black transition-colors duration-200 transform bg-pink-500 rounded-md hover:bg-pink-700 focus:outline-none focus:bg-gray-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
        {/* 02 end  */}
      </>
    </>
  );
}
